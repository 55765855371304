<template>
  <div class="container-fluid container-offset" style="margin-top:6em">
    <div class="row justify-content-md-center">
      <div class="col-6">
        <h2 style="margin-bottom:.5em">Welcome to Global Endowment Management</h2>
        <p>We are passionate investors committed to serving our clients. For more than two decades, our people have stewarded the financial assets of national and international endowments and foundations.</p>
        <p>We are a full-service outsourced investment office providing access to investment managers worldwide within portfolios designed to enable our clients to fulfill their missions.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>
