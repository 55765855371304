
import { Component, Vue, Watch } from "vue-property-decorator";
import Plotly from "plotly.js";

@Component({
  components: {}
})
export default class MapView extends Vue {
  private inputData: any = "";
  private mounted() {
    this.renderMap();
  }

  @Watch("inputData")
  public onDataChanged(value: any, oldValue: any) {
    this.renderMap();
  }

  public renderMap() {
    let locations = [];
    let values = [];

    for (const line of this.inputData.split("\n")) {
      if (line === "") {
        continue;
      }
      const els = line.split(",");
      locations.push(els[0]);
      values.push(+els[1]);
    }

    const maxOfMax = Math.max.apply(Math, values);
    const maxOfMin = Math.abs(Math.min.apply(Math, values));
    const maxMax = Math.max.apply(Math, [maxOfMax, maxOfMin]);

    const scale = [
      [0.0, "#790000"],
      [0.125, "#bb2c22"],
      [0.25, "#eb6955"],
      [0.375, "#fcaf9c"],
      [0.5, "#f5f5f5"],
      [0.625, "#59d786"],
      [0.75, "#1ba255"],
      [0.875, "#006d28"],
      [1.0, "#003c00"]
    ];

    var data = [
      {
        type: "choroplethmapbox",
        geojson:
          "https://raw.githubusercontent.com/johan/world.geo.json/master/countries.geo.json",
        locations: locations,
        z: values,
        zmin: -maxMax,
        zmax: maxMax,
        colorscale: scale,
        marker: {
          opacity: 0.7
        }
      }
    ];

    var layout = {
      mapbox: {
        style: "carto-positron",
        center: { lon: -110, lat: 50 },
        zoom: 0.5
      },
      height: 600,
      margin: { t: 0, b: 0, r: 0, l: 0 },
      font: {
        family: "sans-serif"
      }
    };

    Plotly.newPlot("myDiv", data as any, layout, {
      responsive: true,
      displayModeBar: false
    });
  }
}
