
import VueCookie from 'vue-cookies';

export default class UserService {
    public user: any;
    public KEY_CURRENT_USER = 'currentUser';

    constructor() {
        this.getSelf();
    }

    public setSelf(currentUser: any) {
        (VueCookie as any).set(this.KEY_CURRENT_USER, currentUser, "30d");
    }

    public getSelf() {
        try {
            this.user = (VueCookie as any).get(this.KEY_CURRENT_USER);
            if (this.user) {
                this.user = this.user;
            } else {
                this.user = null;
            }
            return this.user;
        } catch (error) {
            return null;
        }
    }

    public removeSelf() {
        (VueCookie as any).remove(this.KEY_CURRENT_USER);
    }

}
