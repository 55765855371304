import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue';
import LoginView from '../views/LoginView.vue';
import LogoutView from '../views/LogoutView.vue';
import FilingsView from '../views/FilingsView.vue';
import JapanFilersView from "../views/JapanFilersView.vue"
import NPFView from "../views/NPFView.vue";
import UserService from '../services/UserService';
import MapView from '../views/MapView.vue';

const userService = new UserService();


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/map',
    name: 'map',
    component: MapView,
    meta: { requiresAuth: true }
  },
  {
    path: '/13f',
    name: '13f',
    component: FilingsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/japan-filers',
    name: 'japan-filers',
    component: JapanFilersView,
    meta: { requiresAuth: true }
  },
  {
    path: '/enterprise-review',
    name: 'npf',
    component: NPFView,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userService.getSelf()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {

      next();
    }
  } else {
    next();
  }
});

export default router;
