import axios from "@/services/api";
import UserService from "@/services/UserService";

export default class APIService {

    private userService = new UserService();


    public async getFilers() {
        const response = await axios.get(`/filers/`,
            this.getHeaders());
        return response.data;
    }

    public async getJapanFilers() {
        const response = await axios.get(`/japan_filers/`,
            this.getHeaders());
        return response.data;
    }

    public async getNFP(ein: any, kind: any) {
        const response = await axios.get("/npf/?ein=" + ein + '&kind=' + kind, this.getHeaders());
        return response.data;
    }

    public async getNFPPeer(ein: any, year: any, kind: any) {
        const response = await axios.get("/npf_peer/?eins=" + ein + "&year=" + year + '&kind=' + kind, this.getHeaders());
        return response.data;
    }

    public async searchFilers(keyword: string) {
        try {
            const response = await axios.post("/search_filers/", {
                q: keyword
            },
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async getNFPExcel(ein: any, kind: any) {
        await axios.request({
            url: "/npf_export/",
            method: 'POST',
            data: { ein, kind },
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        }).then(({ data }) => {
            const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'data.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }


    private getHeaders() {
        return {
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            }
        };
    }
}
