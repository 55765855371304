
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  filters: {
    formatNumber(value: any, format: any = ",.0f") {
      if (
        value === null ||
        value === undefined ||
        value === "" ||
        value === "None"
      ) {
        return "";
      }
      if (
        format === ".0f" ||
        format === ",.0f" ||
        format === null ||
        format === undefined ||
        format === ""
      ) {
        return new Intl.NumberFormat().format(value.toFixed(0));
      }
      if (format === ".1f" || format === ",.1f") {
        return value.toFixed(1);
      }
      if (format === ".2f" || format === ",.2f") {
        return value.toFixed(2);
      }
      if (format === ".3f" || format === ",.3f") {
        return value.toFixed(3);
      }
      if (format === ",.0f%") {
        return value.toFixed(0) + "%";
      }
      if (format === ",.1f%") {
        return value.toFixed(1) + "%";
      }
      if (format === ",.2f%") {
        return value.toFixed(2) + "%";
      }
      if (format === ",.3f%") {
        return value.toFixed(3) + "%";
      }
      if (format === ",.0f bp") {
        return value.toFixed(0) + " bp";
      }
      if (format === ",.1f bp") {
        return value.toFixed(1) + " bp";
      }
      if (format === ",.2f bp") {
        return value.toFixed(2) + " bp";
      }
      if (format === ".1fx") {
        return value.toFixed(1) + "x";
      }
      if (format === ".2fx") {
        return value.toFixed(2) + "x";
      }
      return value.toFixed(2);
    },

    formatPct(value: any) {
      if (value === "") {
        return "";
      }
      if (value === "None") {
        return "";
      }
      return value.toFixed(1) + "%";
    },
  },
})
export default class EnterpriseReviewTable extends Vue {
  @Prop() public data!: any;
  @Prop() public rowSpecs!: any;
  @Prop() public extras: any;

  private getGradeClass(key: any, vals: any, iidx: any) {
    // is this the last column?
    if (vals && iidx !== vals.length - 1) {
      return '';
    }

    let val: any;
    // get the last value
    if (vals && vals.length > 0) {
      val = vals[vals.length - 1];
    } else {
      return '';
    }

    const mapping: any = {
      'net_tuition_support': [100, 85, 70, 40, 20],
      'other_program_service_revenue_support': [100, 20, 10, 2, -10000],
      'contribution_and_grants': [100, 25, 15, 5, 2],
      'endowment_support': [5, 10, 22.5, 30, 100],
      "endowment_to_debt": [10000, 6, 3, 1.5, 0.75],
      'total_investments_to_debt': [10000, 6, 3, 1.5, 0.75],
      "debt_coverage": [10000, 5, 3, 1.5, 0.75],
      "debt_to_assets": [7.5, 12.5, 22.5, 30, 100],
      "days_cash_on_hand": [10000, 300, 180, 90, 30, 0],
      "ebitda_margin": [10000, 25, 17.5, 10, 5],
      "discount_rate": [20, 30, 47.5, 55, 10000],
      "operating_rev_5y_cagr": [10000, 8, 4, 0, -4],
      "operating_exp_5y_cagr": [1, 2, 4, 6, 10000],
      "spend_rate": [2, 3.75, 5, 7, 10000],
      "contrib_rate": [10000, 4, 2.5, 1, 0.5],
      "net_flow_rate": [10000, -1, -2.5, -4.5, -6.5]
    }
    const signs: any = {
      'endowment_support': -1,
      "debt_to_assets": -1,
      "discount_rate": -1,
      "operating_exp_5y_cagr": -1,
      "spend_rate": -1
    }

    let rng: any;
    let sign = 1;
    if (key in mapping) {
      rng = mapping[key];
    } else {
      return '';
    }
    if (key in signs) {
      sign = signs[key];
    }

    if (sign === 1) {
      if (val > rng[1] && val <= rng[0]) {
        return 'excellent';
      }
      if (val > rng[2] && val <= rng[1]) {
        return 'good'
      }
      if (val > rng[3] && val <= rng[2]) {
        return 'fair'
      }
      if (val > rng[4] && val <= rng[3]) {
        return 'poor'
      }
      if (val <= rng[4]) {
        return 'very-poor'
      }
    } else {
      if (val < rng[0]) {
        return 'excellent';
      }
      if (val < rng[1] && val >= rng[0]) {
        return 'good'
      }
      if (val < rng[2] && val >= rng[1]) {
        return 'fair'
      }
      if (val < rng[3] && val >= rng[2]) {
        return 'poor'
      }
      if (val < rng[4] && val >= rng[3]) {
        return 'very-poor'
      }
    }


    return '';
  }
}
