
import { Component, Vue, Prop } from "vue-property-decorator";
import AuthenticationService from "@/services/AuthenticationService";
import UserService from "@/services/UserService";
import router from "@/router";

@Component({
  components: {}
})
export default class LoginView extends Vue {
  private username = "";
  private password = "";

  private login(evt: any) {
    evt.preventDefault();
    const ds = new AuthenticationService();

    ds.login(this.username, this.password).then(response => {
      this.$router.push("/");
    });
  }
}
