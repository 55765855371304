import axios from "@/services/api";
import UserService from "@/services/UserService";

export default class AuthenticationService {

    private userService: UserService;

    constructor() {
        this.userService = new UserService();
    }

    public async login(username: string, password: string) {
        const response = await axios.post("authenticate/", {
            username,
            password
        });

        if (response.data.token) {
            this.userService.setSelf(JSON.stringify(response.data));
        }
        return response.data;
    }
}
