
import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Table.vue";
import APIService from "@/services/APIService";
import ColorCell from "@/components/ColorCell.vue";

@Component({
  components: {
    TableComponent,
    ColorCell
  }
})
export default class TableView extends Vue {
  private apiService = new APIService();

  private dataSource = [];

  private mounted() {
    this.apiService.getFilers().then(response => {
      this.dataSource = response;
    });
  }

  private colorCellTemplate(attr: any) {
    return {
      template: ColorCell,
      data: {
        gridDataAttr: attr,
        gridTextAfter: "",
        positiveClass: "text-success"
      }
    };
  }

  private aumTemplate() {
    return this.colorCellTemplate("aum");
  }
  private newTemplate() {
    return this.colorCellTemplate("is_new");
  }
  private posTemplate() {
    return this.colorCellTemplate("count");
  }
  private topTemplate() {
    return this.colorCellTemplate("top_ten");
  }
  private mtTemplate() {
    return this.colorCellTemplate("max_turnover");
  }
  private atTemplate() {
    return this.colorCellTemplate("mean_turnover");
  }

  // settings
  private settingsMenu = {
    beta: [
      { key: "gem", text: "GEM Default", disabled: false },
      { key: "lasso", text: "Lasso Regression", disabled: false },
      { key: "policy", text: "Policy Weights (2016)", disabled: false },
      { key: "policy2019", text: "Policy Weights (2019)", disabled: false }
    ]
  };

  private tableSettings = {
    frozenColumns: 0,
    allowFiltering: false,
    allowSorting: false
  };

  private settings = {
    beta: "gem",
    plug: "none",
    horizon: "3y",
    privates: "erzero",
    mode: "all"
  };

  // columns

  private betaColumns = [];

  get betaText() {
    for (var k of this.settingsMenu.beta) {
      if (k.key == this.settings.beta) {
        return k.text;
      }
    }
    return "";
  }
}
