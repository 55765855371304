var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data)?_c('table',{staticClass:"table table-hover table-xs"},[_c('thead',[_c('th'),_vm._l((_vm.data.columns),function(row,idx){return _c('th',{key:idx,staticClass:"text-right"},[_vm._v(" "+_vm._s(row)+" ")])})],2),_c('tbody',_vm._l((_vm.rowSpecs.rows),function(row,key){return _c('tr',{key:key},[_c('th',{class:'indent-' + row.indent + ' ' + (row.css ? row.css : '')},[_vm._v(" "+_vm._s(row.name)+" ")]),_vm._l((_vm.data.rows_dict[key]
          ? _vm.data.rows_dict[key]
          : ['', '', '', '', '', '', '']),function(col,iidx){return _c('td',{key:iidx,staticClass:"text-right",class:(row.css ? row.css : '') +
          ' ' +
          _vm.getGradeClass(key, _vm.data.rows_dict[key], iidx)},[(
            _vm.extras &&
            _vm.extras[_vm.data.columns[iidx]] &&
            key in _vm.extras[_vm.data.columns[iidx]]
          )?_c('div',[(
              (_vm.rowSpecs.hasAvg && iidx < _vm.data.ndata_cols + 1) ||
              iidx < _vm.data.ndata_cols
            )?_c('span',{staticClass:"has-extra",attrs:{"id":'extra-info-' + key + '-' + iidx}},[_vm._v(_vm._s(_vm._f("formatNumber")(col,row.format ? row.format : "")))]):_c('span',{class:{
              'text-success': col > 0,
              'text-danger': col < 0,
            }},[_vm._v(_vm._s(_vm._f("formatPct")(col)))]),(
              _vm.extras &&
              _vm.extras[_vm.data.columns[iidx]] &&
              key in _vm.extras[_vm.data.columns[iidx]]
            )?_c('b-popover',{attrs:{"target":'extra-info-' + key + '-' + iidx,"triggers":"hover","placement":"auto"}},[_c('div',{staticStyle:{"font-size":"90%"}},[_c('table',{staticClass:"table table-sm table-striped",staticStyle:{"margin-bottom":"0"}},[_c('tbody',_vm._l((_vm.extras[_vm.data.columns[iidx]][key]),function(r){return _c('tr',{key:r.k},[_c('td',[_vm._v(_vm._s(r.k))]),_c('td',{staticClass:"text-right",staticStyle:{"padding-left":"15px"}},[_vm._v(" "+_vm._s(r.v)+" ")])])}),0)])])]):_vm._e()],1):_c('div',[(
              (_vm.rowSpecs.hasAvg && iidx < _vm.data.ndata_cols + 1) ||
              iidx < _vm.data.ndata_cols
            )?_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(col,row.format ? row.format : "")))]):_c('span',{class:{
              'text-success': col > 0,
              'text-danger': col < 0,
            }},[_vm._v(_vm._s(_vm._f("formatPct")(col)))])])])})],2)}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }