
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  TreeGridPlugin,
  TreeGridComponent,
  Page,
  Sort,
  Filter,
  ExcelExport,
  Toolbar,
  Freeze
} from "@syncfusion/ej2-vue-treegrid";

@Component({
  components: {},
  provide: {
    treegrid: [Page, Sort, Filter, ExcelExport, Toolbar, Freeze]
  }
})
export default class TableComponent extends Vue {
  @Prop() public dataSource!: any;
  @Prop() public childMapping!: any;
  @Prop() public allowSorting!: any;
  @Prop() public sortSettings!: any;
  @Prop() public allowFiltering!: any;
  @Prop() public treeColumnIndex!: any;
  @Prop() public rowDataBound!: any;

  public filterSettings = { type: "Menu", hierarchyMode: "Parent" };
  public toolbarOptions = ["ExpandAll", "CollapseAll", "ExcelExport"];

  public toolbarClick(args: any) {
    if (args["item"].text === "Excel Export") {
      (this.$refs.treegrid as any).excelExport();
    } else if (args["item"].text === "PDF Export") {
      (this.$refs.treegrid as any).pdfExport();
    } else if (args["item"].text === "Sorting") {
      this.allowSorting = !this.allowSorting;
    } else if (args["item"].text === "Filtering") {
      this.allowFiltering = !this.allowFiltering;
    }
  }
}
