
import { Component, Vue, Watch } from "vue-property-decorator";
import TableComponent from "@/components/Table.vue";
import EnterpriseReviewTable from "@/components/EnterpriseReviewTable.vue";
import APIService from "@/services/APIService";
import ColorCell from "@/components/ColorCell.vue";
import { debounce } from "debounce";
import Plotly from "plotly.js";
import { titleCase } from "title-case";

@Component({
  components: {
    TableComponent,
    EnterpriseReviewTable,
    ColorCell,
  },
  filters: {
    formatNumber(value: any, format: any = ",.0f") {
      try {
        if (
          value === null ||
          value === undefined ||
          value === "" ||
          value === "None"
        ) {
          return "";
        }
        if (
          format === ".0f" ||
          format === ",.0f" ||
          format === null ||
          format === undefined ||
          format === ""
        ) {
          return new Intl.NumberFormat().format(value.toFixed(0));
        }
        if (format === ".1f" || format === ",.1f") {
          return value.toFixed(1);
        }
        if (format === ".2f" || format === ",.2f") {
          return value.toFixed(2);
        }
        if (format === ".3f" || format === ",.3f") {
          return value.toFixed(3);
        }
        if (format === ",.0f%") {
          return value.toFixed(0) + "%";
        }
        if (format === ",.1f%") {
          return value.toFixed(1) + "%";
        }
        if (format === ",.2f%") {
          return value.toFixed(2) + "%";
        }
        if (format === ",.3f%") {
          return value.toFixed(3) + "%";
        }
        if (format === ",.0f bp") {
          return value.toFixed(0) + " bp";
        }
        if (format === ",.1f bp") {
          return value.toFixed(1) + " bp";
        }
        if (format === ",.2f bp") {
          return value.toFixed(2) + " bp";
        }
        if (format === ".1fx") {
          return value.toFixed(1) + "x";
        }
        if (format === ".2fx") {
          return value.toFixed(2) + "x";
        }
        return value.toFixed(2);

      } catch (error) {
        return value;
      }

    },


    formatPct(value: any) {
      if (value === "") {
        return "";
      }
      return value.toFixed(1) + "%";
    },
  },
})
export default class NFPView extends Vue {
  private apiService = new APIService();
  private response = null;
  private selected = { ein: "952816435", name: null };

  private filerQuery = "";
  private options = [];
  private selectedOptions: any = [];
  private additionalEINs = "";

  private loading = false;
  private kind = "ed";
  private activeTabId = 0;

  public incomeStatementRowSpecs = {
    hasAvg: false,
    rows: {
      operating_revenue: {
        name: "Operating Revenue",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      gross_tuition_and_fees: {
        name: "Gross Tuition & Fees",
        hasVal: true,
        indent: 2,
        educationOnly: true,
      },
      net_scholarships_and_discounts: {
        name: "(−) Scholarships & Discounts",
        hasVal: true,
        indent: 2,
        educationOnly: true,
      },
      net_tuition_and_fees: {
        name: "(=) Net Tuition & Fees",
        hasVal: true,
        indent: 1,
        educationOnly: true,
      },
      other_program_service_revenue: {
        name: "Other Program Service Revenue",
        hasVal: true,
        indent: 1,
      },
      contributions_and_grants: {
        name: "Contributions & Grants",
        hasVal: true,
        indent: 1,
      },
      endowment_spending: {
        name: "Endowment Spending",
        hasVal: true,
        indent: 1,
      },
      total_operating_revenue: {
        name: "Total Operating Revenue",
        hasVal: true,
        indent: 0,
        css: "table-secondary",
      },
      e1: { name: "", css: "pt-2 pb-2 " },
      operating_expenses: {
        name: "Operating Expenses",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      salaries_and_benefits: {
        name: "Salaries & Benefits",
        hasVal: true,
        indent: 1,
      },
      depreciation_and_amortizations: {
        name: "Depreciation & Amortizations",
        hasVal: true,
        indent: 1,
      },
      interest: { name: "Interest", hasVal: true, indent: 1 },
      other_expenses: { name: "Other Expenses", hasVal: true, indent: 1 },
      total_operating_expenses: {
        name: "Total Operating Expenses",
        hasVal: true,
        indent: 0,
        css: "table-secondary",
      },
      e3: { name: "", css: "pt-2 pb-2" },
      operating_income: {
        name: "Operating Income",
        hasVal: true,
        indent: 0,
        css: "table-secondary",
      },
      e4: { name: "", css: "pt-2 pb-2 border-0" },
      ebitda: { name: "EBIDA", hasVal: true, indent: 0, css: "border-0" },
    },
  };

  public balanceSheetRowSpecs = {
    hasAvg: false,
    rows: {
      assets: {
        name: "Assets",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      cash_and_cash_equivalents: { name: "Cash & Cash Equivalents", indent: 1 },
      endowment: { name: "Endowment", indent: 1 },
      non_endowment_investments: { name: "Non-Endowment Investments", indent: 1 },
      "pp&e": { name: "PP&E", indent: 1 },
      other_assets: { name: "Other Assets", indent: 1 },
      total_assets: { name: "Total Assets", indent: 0, css: "table-secondary" },
      e1: { name: "", css: "pt-2 pb-2 " },
      liabilities: {
        name: "Liabitilies",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },

      tax_exempt_bond_liabilities: {
        name: "Tax Exempt Bond Liabilities",
        indent: 1,
      },
      other_debt: { name: "Other Debt", indent: 1 },
      other_liabilities: { name: "Other Liabilities", indent: 1 },
      total_liabilities: { name: "Total Liabilities", css: "table-secondary" },
      e2: { name: "", css: "pt-2 pb-2 " },
      net_assets: { name: "Net Assets", css: "table-secondary" },
      unrestricted_net_assets: { name: "Unrestricted Net Assets", indent: 1 },
      permanently_restricted_net_assets: {
        name: "Permanently Restricted Net Assets",
        indent: 1,
      },
      e3: { name: "", css: "pt-2 pb-2 border-0" },
      capex: {
        name: "Estimated CAPEX",
      },
      debt_service: { name: "Estimated Debt Service" },
      debt_service_int: {
        name: "Interest",
        indent: 1,
      },
      debt_service_principal: {
        name: "Principal Repayment (Borrowing)",
        indent: 1,
      },
    },
  };

  public scheduleRowSpecs = {
    hasAvg: true,
    rows: {
      beginning_balance: { name: "Beginning Balance" },
      contributions: { name: "Contributions", indent: 1 },
      ef_pnl: { name: "Net Investment Earnings, Gains & Losses", indent: 1 },
      ef_grants: { name: "Grants or Scholarships", indent: 1 },
      ef_other_expenditures: { name: "Other Expenditures for Facilities and Program", indent: 1 },
      ef_admin_expenses: { name: "Administrative Expenses", indent: 1 },
      ending_balance: { name: "Ending Balance" },
      // expenditures: { name: "Expenditures" },

      e1: { name: "", css: "pt-2 pb-2 border-0" },


      pct_board_designated: {
        name: "% Board Designated",
        format: ",.1f%",
      },
      pct_permanent: { name: "% Permanent", format: ",.1f%" },
      pct_temp: { name: "% Temporarily Restricted", format: ",.1f%" },
    },
  };

  public indicatorRowSpecs = {
    hasAvg: true,
    rows: {
      assets: {
        name: "Budget Dependence",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      net_tuition_support: {
        name: "Net Tuition",
        format: ",.1f%",
        indent: 1,
        educationOnly: true,
      },
      other_program_service_revenue_support: {
        name: "Program Service Revenue (ex-Tuition)",
        format: ",.1f%",
        indent: 1,
      },
      contribution_and_grants: {
        name: "Contributions & Grants",
        format: ",.1f%",
        indent: 1,
      },
      endowment_support: {
        name: "Endowment Support",
        format: ",.1f%",
        indent: 1,
      },

      e1: { name: "", css: "pt-2 pb-2 " },
      bs: {
        name: "Balance Sheet",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      endowment_to_debt: {
        name: "Endowment to Debt",
        format: ".1fx",
        indent: 1,
      },
      total_investments_to_debt: {
        name: "Total Investments to Debt",
        format: ".1fx",
        indent: 1,
      },
      debt_coverage: {
        name: "Unrestricted Reserve Debt Coverage (Net Assets-to-Debt)",
        format: ".1fx",
        indent: 1,
      },
      debt_to_assets: {
        name: "Debt to Assets",
        format: ",.1f%",
        indent: 1,
      },
      days_cash_on_hand: {
        name: "Days Cash on Hand",
        indent: 1,
      },
      e2: { name: "", css: "pt-2 pb-2 " },
      opperf: {
        name: "Operating Performance",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      ebitda_margin: {
        name: "EBIDA Margin",
        format: ",.1f%",
        indent: 1,
      },
      discount_rate: {
        name: "Discount Rate",
        format: ",.1f%",
        indent: 1,
        educationOnly: true,
      },
      operating_rev_5y_cagr: {
        name: "Operating Revenue 5Y CAGR",
        format: ",.1f%",
        indent: 1,
      },
      operating_exp_5y_cagr: {
        name: "Operating Expenses 5Y CAGR",
        format: ",.1f%",
        indent: 1,
      },
      e3: { name: "", css: "pt-2 pb-2 " },
      endowmentflows: {
        name: "Endowment Flows",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      spend_rate: {
        name: "Effective Endowment Spend Rate",
        format: ",.1f%",
        indent: 1,
      },
      contrib_rate: {
        name: "Contribution Rate",
        format: ",.1f%",
        indent: 1,
      },
      net_flow_rate: {
        name: "Net Flow Rate",
        format: ",.1f%",
        indent: 1,
      },
      e4: { name: "", css: "pt-2 pb-2 " },
      moody: {
        name: "Moody's Criteria Metrics",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      moodyOp: {
        name: "Operating Performance (10%)",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      ebitda_margin_2: {
        name: "EBIDA Margin (10%)",
        format: ",.1f%",
        indent: 1,
      },
      moodyFin: {
        name: "Financial Resources & Liquidity (25%)",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      cash_and_inv: {
        name: "Total Cash & Investments (10%)",
        indent: 1,
      },
      cash_and_inv_to_exp: {
        name: "Total Cash & Investments / Operating Expenses (10%)",
        indent: 1,
        format: ".1fx",
      },
      moodyLev: {
        name: "Leverage & Coverage Ratio (20%)",
        hasVal: false,
        indent: 0,
        css: "table-secondary",
      },
      cash_and_inv_to_debt: {
        name: "Total Cash & Investments / Total Adj Debt (10%)",
        indent: 1,
        format: ".1fx",
      },
      debt_service: {
        name: "Annual Debt Service Coverage (EBIDA/Debt Service) (10%)",
        indent: 1,
        format: ".1fx",
      },
    },
  };

  public pfRowSpecs = {
    hasAvg: false,
    rows: {
      investments_public: { name: "Investments – Publicly Traded Securities" },
      investments_other: { name: "Investments – Other Securities" },
      investments_program: { name: "Investments – Program-Related" },
      investments_total: { name: "Total Investments", indent: 0, css: "table-secondary" },
    },
  };



  public isRows = [
    ["Gross Tuition & Fees", "gross_tuition_and_fees"],
    ["Net Scholarships & Discounts", "net_scholarships_and_discounts"],
    ["Net Tuition & Fees", "net_tuition_and_fees"],
    ["Other Program Service Revenue", "other_program_service_revenue"],
    ["Contributions & Grants", "contributions_and_grants"],
    ["Endowment Spending", "endowment_spending"],
    ["Total Operating Revenue", "total_operating_revenue"],
    ["Core Revenue", "core_revenue"],
    ["Salaries & Benefits", "salaries_and_benefits"],
    ["Depreciation & Amortizations", "depreciation_and_amortizations"],
    ["Interest", "interest"],
    ["Other Expenses", "other_expenses"],
    ["Operating Income", "operating_income"],
    ["EBITDA", "ebitda"],
  ];
  public bsRows = [
    ["Cash & Cash Equivalents", "cash_and_cash_equivalents"],
    ["Endowment", "endowment"],
    ["PP&E", "pp&e"],
    ["Other Assets", "other_assets"],
    ["Total Assets", "total_assets"],
    ["Tax Exempt Bond Liabilities", "tax_exempt_bond_liabilities"],
    ["Other Debt", "other_debt"],
    ["Other Liabilities", "other_liabilities"],
    ["Total Liabilities", "total_liabilities"],
    ["Net Assets", "net_assets"],
    ["Unrestricted Net Assets", "unrestricted_net_assets"],
    ["Permanently Restricted Net Assets", "permanently_restricted_net_assets"],
  ];
  public schRows = [
    ["Ending Balance", "ending_balance"],
    ["Expenditures", "expenditures"],
    ["Contributions", "contributions"],
    ["Percent Board Designated", "pct_board_designated"],
    ["Percent Permanent", "pct_permanent"],
  ];

  private mounted() { }

  private calculate() {
    this.loading = true;

    let inEins = this.allEins;
    if (inEins.includes(",")) {
      this.calculatePeer(inEins);
    } else {
      this.calculateOne(inEins);
    }
  }

  private calculateOne(inEins: any) {
    this.apiService.getNFP(inEins, this.kind).then((response) => {
      if (response.status === 1) {
        this.response = response;
        try {
          if (this.activeTabId === 4) {
            this.plot();
          }
        } catch (error) {
          // should figure out what to do here
        }
      } else {
        this.$bvToast.toast(response.msg, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
      this.loading = false;
    });
  }

  private getContractorName(row: any) {
    try {
      if ('BusinessName' in row.ContractorName) {
        return row.ContractorName.BusinessName.BusinessNameLine1Txt;
      } else {
        return row.ContractorName.PersonNm;
      }
    } catch (error) {
      return "#Cannot parse!"
    }


  }

  private calculatePeer(inEins: any) {
    this.loading = true;
    this.apiService.getNFPPeer(inEins, "2020", this.kind).then((response) => {
      if (response.status === 1) {
        this.response = response;
        try {
          if (this.activeTabId === 4) {
            this.plot();
          }
        } catch (error) {
          // should figure out what to do here
        }
      } else {
        this.$bvToast.toast(response.msg, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
      this.loading = false;
    });
  }

  private onSearch(search: any, loading: any) {
    loading(true);
    this.search(this);
  }

  private search = debounce((vm: any) => {
    vm.apiService.searchFilers(vm.filerQuery).then((response: any) => {
      vm.options = response;
    });
  }, 250);

  @Watch("filerQuery")
  public onFilerQueryChange(value: any, oldValue: any) {
    this.searchForFiler();
  }

  private searchForFiler() {
    this.search(this);
  }
  private addOption(option: any) {
    const idx = this.selectedOptions.findIndex((e: any) => {
      return e.ein === option.ein;
    });
    if (idx === -1) {
      this.selectedOptions.push(option);
    }
  }
  private removeOption(option: any) {
    const idx = this.selectedOptions.findIndex((e: any) => {
      return e.ein === option.ein;
    });
    this.selectedOptions.splice(idx, 1);
  }
  private toTitleCase(s: string) {
    return s;
  }
  private chooserModalShown() {
    (document.getElementById("inputFilerQuery") as any).focus();
    (document.getElementById("inputFilerQuery") as any).select();
  }

  private chooserModalHidden() { }

  private relevantRowSpecs(spec: any) {
    const specCopied = JSON.parse(JSON.stringify(spec));
    specCopied.rows = {};
    for (const row in spec.rows) {
      const val = JSON.parse(JSON.stringify(spec.rows[row]));
      if (
        (val.educationOnly && this.kind === "ed") ||
        val.educationOnly === undefined
      ) {
        if (
          this.kind === "foundation" &&
          row === "other_program_service_revenue"
        ) {
          val.name = "Program Service Revenue";
        }
        if (
          this.kind === "foundation" &&
          row === "other_program_service_revenue_support"
        ) {
          val.name = "Program Service Revenue";
        }

        specCopied.rows[row] = val;
      }
    }
    return specCopied;
  }

  private plot() {
    const response: any = this.response;

    const colors = ["#C00000", "#000000", "#A7A8AA"];
    const legendStyle = {
      orientation: "h",
      xanchor: "center",
      x: 0.5,
      y: 1.05,
      yanchor: "bottom",
      font: {
        size: 12,
      },
    };

    var layout = {
      height: 300,
      margin: { t: 0, b: 35, r: 50, l: 35, autoexpand: true },
      font: {
        family: "sans-serif",
      },
      barmode: "stack",
      legend: legendStyle,
      yaxis: {
        showline: false,
        showgrid: false,
        automargin: true,
        hoverformat: ".2f",
      },
      yaxis2: {
        showline: false,
        showgrid: false,
        overlaying: "y",
        anchor: "x",
        automargin: true,
        side: "right",
        hoverformat: ".2f",
      },
      hoverlabel: {
        font: {
          size: 11,
        },
        namelength: 100,
      },
    };
    var layoutUnstacked = {
      height: 300,
      margin: { t: 0, b: 35, r: 50, l: 35, autoexpand: true },
      font: {
        family: "sans-serif",
      },
      legend: legendStyle,
      yaxis: {
        showline: false,
        showgrid: false,
        automargin: true,
        hoverformat: ".2f",
      },
      yaxis2: {
        showline: false,
        showgrid: false,
        overlaying: "y",
        anchor: "x",
        automargin: true,
        side: "right",
        hoverformat: ".2f",
      },
      hoverlabel: {
        font: {
          size: 11,
        },
        namelength: 100,
      },
    };
    var layoutRelative = {
      height: 300,
      margin: { t: 0, b: 35, r: 50, l: 35, autoexpand: true },
      font: {
        family: "sans-serif",
      },
      barmode: "relative",
      legend: legendStyle,
      yaxis: {
        showline: false,
        showgrid: false,
        automargin: true,
        hoverformat: ".2f",
      },
      yaxis2: {
        showline: false,
        showgrid: false,
        overlaying: "y",
        anchor: "x",
        automargin: true,
        side: "right",
        hoverformat: ".2f",
      },
      hoverlabel: {
        font: {
          size: 11,
        },
        namelength: 100,
      },
    };

    // figure 1

    var xVals = response.income_statement.columns.filter((e: any) => {
      return e.length === 10;
    });
    var yGrants =
      response.income_statement.rows_dict.contributions_and_grants.slice(
        0,
        xVals.length
      );
    var yEndowments =
      response.income_statement.rows_dict.endowment_spending.slice(
        0,
        xVals.length
      );
    var yNetTuitions =
      response.income_statement.rows_dict.net_tuition_and_fees.slice(
        0,
        xVals.length
      );
    var yDR = response.indicators.rows_dict.discount_rate.slice(
      0,
      xVals.length
    );
    var data: any;
    if (this.kind === "ed") {
      data = [
        {
          x: xVals,
          y: yDR,
          type: "line+marker",
          name: "Discount Rate",
          yaxis: "y2",
          line: { color: colors[0] },
          marker: { size: 8 },
        },
        {
          x: xVals,
          y: yNetTuitions,
          type: "bar",
          name: "Net Tuition & Fees",
          marker: { color: colors[2] },
        },
        {
          x: xVals,
          y: yEndowments,
          type: "bar",
          name: "Endowment Draw          ",
          marker: { color: colors[1] },
        },
        {
          x: xVals,
          y: yGrants,
          type: "bar",
          name: "Grants & Other",
          marker: { color: colors[0] },
        },
      ];
    } else {
      data = [
        {
          x: xVals,
          y: yEndowments,
          type: "bar",
          name: "Endowment Draw          ",
          marker: { color: colors[1] },
        },
        {
          x: xVals,
          y: yGrants,
          type: "bar",
          name: "Grants & Other",
          marker: { color: colors[0] },
        },
      ];
    }

    // figure 2
    var xVals2 = response.income_statement.columns.filter((e: any) => {
      return e.length === 10;
    });
    var yRev =
      response.income_statement.rows_dict.total_operating_revenue.slice(
        0,
        xVals2.length
      );
    var yEBITDA = response.income_statement.rows_dict.ebitda.slice(
      0,
      xVals2.length
    );
    var yEBITDAMargin = response.indicators.rows_dict.ebitda_margin.slice(
      0,
      xVals2.length
    );

    var data2 = [
      {
        x: xVals2,
        y: yRev,
        type: "bar",
        name: "Operating Revenue",
        marker: { color: colors[2] },
      },
      {
        x: xVals2,
        y: yEBITDA,
        type: "bar",
        name: "EBIDA",
        marker: { color: colors[1] },
      },

      {
        x: xVals2,
        y: yEBITDAMargin,
        type: "line+marker",
        name: "EBIDA Margin",
        yaxis: "y2",
        line: { color: colors[0] },
        marker: { size: 8 },
      },
    ];

    // figure 3
    var xVals3 = response.indicators.columns.filter((e: any) => {
      return e.length === 10;
    });
    if ("contrib_rate" in response.indicators.rows_dict) {
      var yInflow = response.indicators.rows_dict.contrib_rate.slice(
        0,
        xVals3.length
      );
      var yOutflow = response.indicators.rows_dict.spend_rate
        .slice(0, xVals3.length)
        .map((e: any) => {
          return -e;
        });
      var yNetflow = response.indicators.rows_dict.net_flow_rate.slice(
        0,
        xVals3.length
      );
      var data3 = [
        {
          x: xVals3,
          y: yNetflow,
          type: "line+marker",
          name: "Net Rate (%)",
          line: { color: colors[0] },
          marker: { size: 8 },
        },

        {
          x: xVals3,
          y: yOutflow,
          type: "bar",
          name: "Outflow Rate (%)",
          marker: { color: colors[2] },
        },
        {
          x: xVals3,
          y: yInflow,
          type: "bar",
          name: "Inflow Rate (%)",
          marker: { color: colors[1] },
        },
      ];
    } else {
      var date3 = [];
    }

    // figure 4
    var xVals4 = response.indicators.columns.filter((e: any) => {
      return e.length === 10;
    });
    var yEndowmentSize =
      response.endowment_schedule.rows_dict.ending_balance.slice(
        0,
        xVals4.length
      );

    var i;
    var yDonorRestricted = [];
    for (i = 0; i < xVals4.length; ++i) {
      yDonorRestricted.push(
        (response.endowment_schedule.rows_dict.pct_permanent[i] *
          yEndowmentSize[i]) /
        100
      );
    }
    var yTempRestricted = [];
    for (i = 0; i < xVals4.length; ++i) {
      yTempRestricted.push(
        (response.endowment_schedule.rows_dict.pct_temp[i] *
          yEndowmentSize[i]) /
        100
      );
    }

    var yBoardDesignated = [];
    for (i = 0; i < xVals4.length; ++i) {
      yBoardDesignated.push(
        (response.endowment_schedule.rows_dict.pct_board_designated[i] *
          yEndowmentSize[i]) /
        100
      );
    }

    var yEndowmentSupport =
      response.indicators.rows_dict.endowment_support.slice(0, xVals4.length);
    var data4 = [
      {
        x: xVals4,
        y: yEndowmentSupport,
        type: "line+marker",
        name: "Endowment Support (% Op. Exp.)",
        line: { color: colors[0] },
        yaxis: "y2",
        marker: { size: 8 },
      },
      {
        x: xVals3,
        y: yBoardDesignated,
        type: "bar",
        name: "Board-Designated",
        marker: { color: colors[0] },
      },
      {
        x: xVals3,
        y: yTempRestricted,
        type: "bar",
        name: "Temp. Restricted",
        marker: { color: colors[1] },
      },

      {
        x: xVals3,
        y: yDonorRestricted,
        type: "bar",
        name: "Donor Restricted",
        marker: { color: colors[2] },
      },
    ];

    setTimeout(() => {
      for (const el of [
        "chartRevenue",
        "chartOp",
        "chartFlows",
        "chartSupport",
      ]) {
        const element = document.getElementById(el) as any;
        if (element !== undefined && element !== null) {
          Plotly.purge(element);
        }
      }

      Plotly.newPlot("chartRevenue", data as any, layout as any, {
        responsive: true,
        displayModeBar: false,
      });

      Plotly.newPlot("chartOp", data2 as any, layoutUnstacked as any, {
        responsive: true,
        displayModeBar: false,
      });

      Plotly.newPlot("chartFlows", data3 as any, layoutRelative as any, {
        responsive: true,
        displayModeBar: false,
      });

      Plotly.newPlot("chartSupport", data4 as any, layout as any, {
        responsive: true,
        displayModeBar: false,
      });
    }, 120);
  }

  private tabActivated(a: any, b: any, c: any) {
    this.activeTabId = a;
    if (a === 4) {
      this.plot();
    }
  }

  private kindChanged() {
    this.calculate();
  }

  private exportExcel() {
    this.apiService.getNFPExcel(this.allEins, this.kind).then((response) => {
      // this.working = false;
    });
  }

  get isPeerMode() {
    return this.allEins.includes(",");
  }



  get allEins() {
    const eins = this.selectedOptions.map((e: any) => {
      return e.ein;
    });
    if (this.additionalEINs) {
      eins.push(this.additionalEINs);
    }
    let inEins = "";
    if (eins.length === 1) {
      inEins = eins[0];
    } else {
      inEins = eins.join(",");
    }

    return inEins.replace(" ", "");
  }
}
