
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ColorCell extends Vue {
  public data = {};

  get attr() {
    return (this.data as any).gridDataAttr;
  }

  get textAfter() {
    return (this.data as any).gridTextAfter;
  }

  get value() {
    return (this.data as any)[this.attr];
  }

  get positiveClass() {
    return (this.data as any).positiveClass;
  }

  get decimals() {
    return (this.data as any).decimals ? (this.data as any).decimals : 1;
  }
}
