
import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/components/Table.vue";
import APIService from "@/services/APIService";
import ColorCell from "@/components/ColorCell.vue";

@Component({
  components: {
    TableComponent,
    ColorCell
  }
})
export default class JapanFilersView extends Vue {
  private apiService = new APIService();

  private dataSource = [];

  private mounted() {
    this.apiService.getJapanFilers().then(response => {
      this.dataSource = response;
    });
  }

  private colorCellTemplate(attr: any) {
    return {
      template: ColorCell,
      data: {
        gridDataAttr: attr,
        gridTextAfter: "",
        positiveClass: "text-success"
      }
    };
  }

  private aumTemplate() {
    return this.colorCellTemplate("aum");
  }
  private posTemplate() {
    return this.colorCellTemplate("count");
  }
  private topTemplate() {
    return this.colorCellTemplate("top_ten");
  }
  private mtTemplate() {
    return this.colorCellTemplate("max_turnover");
  }
  private atTemplate() {
    return this.colorCellTemplate("mean_turnover");
  }
}
