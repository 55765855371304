import Vue from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import Vue2Filters from 'vue2-filters';
import UserService from "@/services/UserService";
import { TreeGridPlugin } from '@syncfusion/ej2-vue-treegrid';
import vSelect from 'vue-select';

import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.use(Vue2Filters);
Vue.use(TreeGridPlugin);

Vue.component('v-select', vSelect as any);
(VueCookies as any).config('30d');
Vue.prototype.$userService = new UserService();

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');